<template>
  <div id="myList" class="myList">
    <ul>
      <li v-for="(item,index) in truncatedList" :key="index" >
          <router-link :to="{ path: '/goods/details', query: {commodityId:item.commodityId} }">
            <img :src="item.commodityImage" alt />
            <h2>{{item.title}}</h2>
            <h3>{{item.defaultPropertiesShow}}</h3>
            <p>
              <span>{{item.minimumRent}}元</span>
              <span
                v-show="item.product_title != item.brand"
                class="del"
              >{{item.minimumRent}}元</span>
            </p>
          </router-link>
      </li>
      <li v-show="isMore && list.length>=1" id="more">
        <router-link :to="{ path: '/goods', query: {selectedSort:selectedSort} }">
          浏览更多
          <i class="el-icon-d-arrow-right"></i>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MyList",
  // list为父组件传过来的商品列表
  // isMore为是否显示“浏览更多”
  // selectedSort为判断是更多哪个场景的值
  // pagename为进入页面的名称
  props: ["list", "isMore" ,"selectedSort","pagename"],
  data() {
    return {};
  },
  computed: {
   
    truncatedList() {
      if(this.pagename == "home"){
        if (this.list.length > 7) {
          return this.list.slice(0, 7);
        } else if(this.list.length == 4) {
          return this.list.slice(0, 3);
        }else{
          return this.list
        }
      }else{
        return this.list
      }
    }
  },
  created(){
 
  },
  methods: {
    
  }
};
</script>
<style scoped>
.myList ul li {
  z-index: 1;
  float: left;
  width: 234px;
  height: 270px;
  padding: 20px 0 10px;
  margin: 0 0 14.5px 13.7px;
  background-color: white;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
}
.myList ul li:hover {
  z-index: 2;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}

.myList ul li img {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: block;
  width: 160px;
  height: 160px;
  background: url(../assets/imgs/placeholder.png) no-repeat 50%;
  margin: 0 auto;
}
.myList ul li:hover img {
  z-index: 2;
  transition: all 0.2s linear;
  transform: scale(1.2);
}
.myList ul li h2 {
  margin: 25px 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.myList ul li h3 {
  margin: 5px 10px;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #b0b0b0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.myList ul li p {
  margin: 10px 10px 10px;
  text-align: center;
  color: red;
}
.myList ul li p .del {
  margin-left: 0.5em;
  color: #b0b0b0;
  text-decoration: line-through;
}
.myList #more {
  text-align: center;
  line-height: 280px;
}
.myList #more a {
  font-size: 18px;
  color: #333;
}
.myList #more a:hover {
  color: #409EFF;
}

.myList ul li .delete {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}
.myList ul li:hover .delete {
  display: block
}
.myList ul li .delete:hover {
  color: #409EFF;
}
</style>