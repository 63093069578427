<template>
  <div id="myLogin">
    <el-dialog title="登录" width="320px" center :visible.sync="isLogin">
      <el-form :model="LoginUser" :rules="rules" status-icon ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="mobile">
          <el-input prefix-icon="el-icon-user-solid" placeholder="请输入手机号" v-model="LoginUser.mobile"></el-input>
        </el-form-item>
        <el-form-item prop="verCode">
          <el-input
            prefix-icon="el-icon-view"
            placeholder="请输入验证码"
            v-model="LoginUser.verCode"
          >
           <el-button slot="append" type="text" @click="getVerifyCode" :loading="downCode != 60"><span class="verify">{{downCode ==60 ? "获取验证码" :`${downCode}s` }}</span></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="primary" @click="Login" style="width:100%;">登录</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "MyLogin",
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const userNameRule = /^1[3-9]\d{9}$/;
      if (userNameRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("请正确输入手机号"));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入验证码"));
      }
      this.$refs.ruleForm.validateField("checkPass");
    };
    return {
      timer: null,
      downCode:60,
      LoginUser: {
        mobile: "",
        pass: ""
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        mobile: [{ validator: validateName, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }]
      }
    };
  },
  computed: {
    // 获取vuex中的showLogin，控制登录组件是否显示
    isLogin: {
      get() {
        return this.$store.getters.getShowLogin;
      },
      set(val) {
        this.$refs["ruleForm"].resetFields();
        this.setShowLogin(val);
      }
    }
  },
  methods: {
    ...mapActions(["setUser", "setToken","setShowLogin"]),
    Login() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate(valid => {
        //如果通过校验开始登录
        if (valid) {
          let mobile = this.LoginUser.mobile
          this.$axios
            .post("/zuwu-api/official/login", {
              mobile: this.LoginUser.mobile,
              verCode: this.LoginUser.verCode
            })
            .then(({data}) => {
              if (data.code == 200) {
                // 隐藏登录组件
                this.isLogin = false;
                // 登录信息存到本地

                let user = "*******" + mobile.slice(-4);
                localStorage.setItem("user", user);
                // 登录信息存到vuex
                this.setUser(user);
                let token = data.data;
                localStorage.setItem("token", token);
                // 登录信息存到vuex
                this.setToken(token);
                // 弹出通知框提示登录成功信息
                this.notifySucceed(data.msg);

                this.$router.push('/order')
              } else {
                // 清空输入框的校验状态
                this.$refs["ruleForm"].resetFields();
                // 弹出通知框提示登录失败信息
                this.notifyError(data.msg);
              }
            })
            .catch(err => {
              return Promise.reject(err);
            });
        } else {
          return false;
        }
      });
    },
    getVerifyCode(){
      let userNameRule = /^1[3-9]\d{9}$/
      if(userNameRule.test(this.LoginUser.mobile)){
        this.$axios.post("/zuwu-api/official/sms",{
          mobile:this.LoginUser.mobile
        }).then(({data})=>{
          if(data.code==200){
            this.$message.success('发送成功')
            this.timer =  setInterval(()=>{
              this.downCode--
              if(this.downCode==0){
                this.downCode = 60
                clearInterval(this.timer)
              }
            },1000)
          }else{
            this.$message.warning(data.msg)
          }
        }).catch(error=>{
          console.log('获取失败',error);
        })
      }else{
        this.$message.warning('请输入正确的手机号')
      }
    }
  }
};
</script>
<style scoped>
.el-input /deep/.el-input-group__append .el-button{
  width: 100px;
  background-color: #409EFF;
  color: #fff;
}
</style>